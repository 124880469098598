// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
    border: 12px solid #fbbf24;
    border-radius: 50%;
    border-top: 12px solid #0ea5e9;
    width: 64px;
    height: 64px;
    /* Safari */
    animation: spinLoader 2s linear infinite;
}

@keyframes spinLoader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/loader/style.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,8BAA8B;IAC9B,WAAW;IACX,YAAY;IAEZ,WAAW;IACX,wCAAwC;AAC5C;;AAYA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".loader {\n    border: 12px solid #fbbf24;\n    border-radius: 50%;\n    border-top: 12px solid #0ea5e9;\n    width: 64px;\n    height: 64px;\n    -webkit-animation: spinLoader 2s linear infinite;\n    /* Safari */\n    animation: spinLoader 2s linear infinite;\n}\n\n@-webkit-keyframes spinLoader {\n    0% {\n        -webkit-transform: rotate(0deg);\n    }\n\n    100% {\n        -webkit-transform: rotate(360deg);\n    }\n}\n\n@keyframes spinLoader {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
